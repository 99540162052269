.panel {
    height: 100vh;
    position: sticky;
    top: 0;
  }
  
  .section {
    width: 100%;
    height: 100vh;
  }
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .column {
    flex-direction: column;
    gap: 1rem;
  }
  
  .boxes-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
  