@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


h2 {
  font-size: 20px;
  text-align: center;
  font-weight: 900;
}

.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.paragraph {
 
  font-weight: 700;
  font-size: 16px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
}



.mask {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.385);
  left: 0;
  top: 0;
  opacity: 0.8;
}

.box {
  width: 15rem;
  height: 5rem;
  display: flex;
  align-items: left;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  background-color: var(--green);
  font-weight: 600;
  color: white;
  line-height: 1.2;
  will-change: transform;
  margin-bottom: 1.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 10px;

}

.funnel-header {
  background-color: magenta;
  color: white;
  text-align: center;
  padding: 20px;
}
.content {
  padding: 20px;
  background-color: white;
  color:black;
}
.key-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color:black;
}

.key-row{
  display:flex;
  flex-direction: row;
}

.five-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 30%;
  border-radius: 5px;
  margin: 20px;
  padding: 40px 20px;
  opacity:0;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-container {
  padding: 2px 16px;
}

.arrow{
  opacity: 0;
}