/* Typography */

@font-face {
    font-display: block;
    font-family: Mori;
    font-style: normal;
    font-weight: 400;
    src: url(https://gsap.com/fonts/Mori/PPMori-Regular.woff) format('woff');
  }
  
  @font-face {
    font-display: block;
    font-family: Mori;
    font-style: normal;
    font-weight: 600;
    src: url(https://gsap.com/fonts/Mori/PPMori-SemiBold.woff) format('woff');
  }
  
  @font-face {
    font-display: block;
    font-family: Fraktion Mono;
    font-style: normal;
    font-weight: 400;
    src: url(https://gsap.com/fonts/Fraktion/PPFraktionMono-Bold.woff)
      format('woff');
  }
  
  :root {
    --color-shockingly-green: #0ae448;
    --color-just-black: #0e100f;
    --color-surface-white: #fffce1;
    --color-pink: #fec5fb;
    --color-shockingly-pink: #f100cb;
    --color-orangey: #ff8709;
    --color-lilac: #9d95ff;
    --color-lt-green: #abff84;
    --color-blue: #00bae2;
    --color-grey: gray;
    --color-surface75: #bbbaa6;
    --color-surface50: #7c7c6f;
    --color-surface25: #42433d;
    --gradient-macha: linear-gradient(
      114.41deg,
      var(--color-shockingly-green) 20.74%,
      var(--color-lt-green) 65.5%
    );
    --gradient-orange-crush: linear-gradient(
      111.45deg,
      var(--color-orangey) 19.42%,
      #f7bdf8 73.08%
    );
    --gradient-lipstick: linear-gradient(
      165.72deg,
      #f7bdf8 21.15%,
      #cd237f 81.93%
    );
    --gradient-purple-haze: linear-gradient(
      153.58deg,
      #f7bdf8 32.25%,
      #2f3cc0 92.68%
    );
    --gradient-skyfall: linear-gradient(
      131.77deg,
      #0a157a 30.82%,
      #15bfe4 81.82%
    );
    --gradient-emerald-city: linear-gradient(
      166.9deg,
      var(--color-shockingly-green) 53.19%,
      #0085d0 107.69%
    );
    --gradient-summer-fair: linear-gradient(
      144.02deg,
      var(--color-blue) 4.56%,
      var(--color-pink) 72.98%
    );
    --color-core-green: #dfffd1;
    --color-core-green-lt: #f3ffee;
    --color-core-gradient: radial-gradient(
      89.08% 84.62% at 16.54% 78.46%,
      #fbfefa 0%,
      #c9f6b4 39.58%,
      #abff84 77.6%,
      #2fee65 100%
    );
    --color-core-button-gradient: linear-gradient(
      114.41deg,
      #0ae448 20.74%,
      #abff84 65.5%
    );
    --color-core-heading-gradient: linear-gradient(
        180deg,
        #d6ffc3 0%,
        rgba(214, 255, 195, 0) 100%
      ),
      #f3ffee;
    --color-core-intro-gradient: linear-gradient(
        144.5deg,
        #e8ffdd 65.09%,
        #7dea7b 122.73%
      ),
      linear-gradient(311.31deg, #7ef89e 36.08%, #e5ffd9 106.98%);
    --color-text-purple: #d2ceff;
    --color-text-purple-lt: #dfdcff;
    --color-text-gradient: radial-gradient(
      129.03% 100% at 120.97% 81.45%,
      #dfdcff 27.08%,
      #a69eff 100%
    );
    --color-svg-tangerine: #ffe3c7;
    --color-svg-tangerine-lt: #fff0e0;
    --color-svg-gradient: radial-gradient(
      70.77% 70.77% at 0% 70.77%,
      #ffd9b0 0%,
      #fd9f3b 80.73%,
      #ff8709 100%
    );
    --color-svg-heading-gradient: linear-gradient(
        180deg,
        #ffbd77 0%,
        rgba(254, 197, 251, 0) 100%
      ),
      #ffe4c7;
    --color-ui-blue: #bef3fe;
    --color-ui-blue-lt: #e1faff;
    --color-ui-blue-codeblk: #f6feff;
    --color-ui-text-gradient: linear-gradient(
      168.89deg,
      #fec5fb -21.3%,
      #00bae2 89.88%
    );
    --color-ui-code-blocktext-gradient: linear-gradient(
      142.91deg,
      #cef6ff 18.75%,
      #a6efff 54.93%
    );
    --color-ui-gradient: radial-gradient(
      78.77% 78.77% at 71.71% 30.77%,
      #f0fcff 0%,
      #9bedff 67.21%,
      #98ecff 76.04%,
      #5be1ff 84.9%,
      #00bae2 94.79%
    );
    --color-ui-gradient-background: linear-gradient(
      137.1deg,
      #ecfcff 27.5%,
      #a6efff 94.09%
    );
    --color-ui-gradient-flip-background: radial-gradient(
      140% 190% at 117.54% 131.12%,
      #f0fcff 0%,
      #9bedff 25.52%,
      #98ecff 42.71%,
      #5be1ff 60.94%,
      #00bae2 94.79%
    );
    --color-scroll-pink: #ffd7fd;
    --color-scroll-pink-lt: #ffe9fe;
    --color-scroll-gradient: linear-gradient(
      317.42deg,
      #ffe9fe 10.4%,
      #ff96f9 83.03%
    );
    --ease-in: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    --ease-out: cubic-bezier(0.23, 1, 0.32, 1);
    --ease-in-out: cubic-bezier(0.86, 0, 0.07, 1);
    --ease-out-quart: cubic-bezier(0.175, 0.79, 0.38, 0.905);
    --ease-in-out-quart: cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  
  :root {
    --dark: var(--color-just-black);
    --grey-dark: #42433d;
    --light: var(--color-surface-white);
    --mid: #7c7c6f;
    --grey: #gray;
    --gray: #gray;
    --green: var(--color-shockingly-green);
    --green-dark: #0ae448;
    --green-light: var(--color-lt-green);
    --blue: var(--color-blue);
    --purple: var(--color-lilac);
    --red: #cd237f;
    --orange: var(--color-orangey);
    accent-color: var(--color-shockingly-green);
  }
  /* lovely fluid fonts */
  
  :root {
    --fluid-min-width: 320;
    --fluid-max-width: 1140;
  
    --fluid-screen: 100vw;
    --fluid-bp: calc(
      (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
        (var(--fluid-max-width) - var(--fluid-min-width))
    );
  }
  
  @media screen and (min-width: 1140px) {
    :root {
      --fluid-screen: calc(var(--fluid-max-width) * 1px);
    }
  }
  
  :root {
    --f-0-min: 16;
    --f-0-max: 18;
    --step-0: calc(
      ((var(--f-0-min) / 16) * 1rem) + (var(--f-0-max) - var(--f-0-min)) *
        var(--fluid-bp)
    );
  
    --f-1-min: 16;
    --f-1-max: 22.5;
    --step-1: calc(
      ((var(--f-1-min) / 16) * 1rem) + (var(--f-1-max) - var(--f-1-min)) *
        var(--fluid-bp)
    );
  
    --f-2-min: 23.04;
    --f-2-max: 28.13;
    --step-2: calc(
      ((var(--f-2-min) / 16) * 1rem) + (var(--f-2-max) - var(--f-2-min)) *
        var(--fluid-bp)
    );
  
    --f-3-min: 27.65;
    --f-3-max: 35.16;
    --step-3: calc(
      ((var(--f-3-min) / 16) * 1rem) + (var(--f-3-max) - var(--f-3-min)) *
        var(--fluid-bp)
    );
  
    --f-4-min: 33.18;
    --f-4-max: 46;
    --step-4: calc(
      ((var(--f-4-min) / 16) * 1rem) + (var(--f-4-max) - var(--f-4-min)) *
        var(--fluid-bp)
    );
  }
  
  /* fluid paddings */
  
  :root {
    --fluid-min-width: 320;
    --fluid-max-width: 1140;
  
    --fluid-screen: 100vw;
    --fluid-bp: calc(
      (var(--fluid-screen) - var(--fluid-min-width) / 16 * 1rem) /
        (var(--fluid-max-width) - var(--fluid-min-width))
    );
  }
  
  @media screen and (min-width: 1140px) {
    :root {
      --fluid-screen: calc(var(--fluid-max-width) * 1px);
    }
  }
  
  :root {
    --fc-s-min: (var(--f-0-min, 21));
    --fc-s-max: (var(--f-0-max, 24));
  
    --fc-m-min: (var(--fc-s-min) * 6);
    --fc-m-max: (var(--fc-s-max) * 6);
  
    --fc-l-min: (var(--fc-s-min) * 8);
    --fc-l-max: (var(--fc-s-max) * 8);
  
    --fc-xl-min: (var(--fc-s-min) * 10);
    --fc-xl-max: (var(--fc-s-max) * 10);
  
    /* T-shirt sizes */
    --space-s: calc(
      ((var(--fc-s-min) / 16) * 1rem) + (var(--fc-s-max) - var(--fc-s-min)) *
        var(--fluid-bp)
    );
    --space-m: calc(
      ((var(--fc-m-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-m-min)) *
        var(--fluid-bp)
    );
    --space-l: calc(
      ((var(--fc-l-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-l-min)) *
        var(--fluid-bp)
    );
    --space-xl: calc(
      ((var(--fc-xl-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-xl-min)) *
        var(--fluid-bp)
    );
  
    /* One-up pairs */
    --space-s-m: calc(
      ((var(--fc-s-min) / 16) * 1rem) + (var(--fc-m-max) - var(--fc-s-min)) *
        var(--fluid-bp)
    );
    --space-m-l: calc(
      ((var(--fc-m-min) / 16) * 1rem) + (var(--fc-l-max) - var(--fc-m-min)) *
        var(--fluid-bp)
    );
    --space-l-xl: calc(
      ((var(--fc-l-min) / 16) * 1rem) + (var(--fc-xl-max) - var(--fc-l-min)) *
        var(--fluid-bp)
    );
  }
  
  /* commonly used utils */
  
  .pad-s {
    padding-top: var(--space-s);
    padding-bottom: var(--space-s);
  }
  
  .pad-m {
    padding-top: var(--space-m);
    padding-bottom: var(--space-m);
  }
  
  .pad-l {
    padding-top: var(--space-l);
    padding-bottom: var(--space-l);
  }
  
  .pad-xl {
    padding-top: var(--space-xl);
    padding-bottom: var(--space-xl);
  }
  
  .flow > * + * {
    margin-top: 1em;
  }
  
  .text-center {
    text-align: center;
  }
  

  .circle {
    width: 75px;
    height: 75px;
    border-radius: 99px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--green);
    font-weight: 600;
    color: var(--dark);
    line-height: 1.2;
    will-change: transform;
  }
  
  /* always doing this */
  .flex-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  
  /* and this */
  
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* colors */
  
  .green {
    background-color: var(--green);
  }
  .blue {
    background-color: var(--blue);
  }
  .purple {
    background-color: var(--purple);
  }
  .red {
    background-color: var(--red);
  }
  .orange {
    background-color: var(--orange);
  }
  
  .dark {
    background-color: var(--dark);
    color: var(--light);
  }
  
  .light {
    background-color: var(--light);
    color: var(--dark);
  }
  
  .text-light {
    color: var(--light);
  }
  
  .text-dark {
    color: var(--dark);
  }
  
  .gray,
  .grey {
    background-color: var(--grey);
  }
  
  body {
    color: var(--color-surface-white);
    background-color: var(--dark);
    font-family: Mori, sans-serif;
    font-weight: 400;
    margin: 0;
    font-size: var(--step-0);
    line-height: 1.45;
  }
  
  h1,
  h2,
  h3,
  h4 {
    margin: 10px 0 10px 0;
    font-weight: 400;
    line-height: 1.2;
  }
  
  h1,
  .h1 {
    font-size: var(--step-4);
  }
  
  h2,
  .h2 {
    font-size: var(--step-3);
  }
  
  h3,
  .h3 {
    font-size: var(--step-2);
  }
  
  h4,
  .h4,
  .lead {
    font-size: var(--step-1);
  }
  
  a {
    color: var(--green);
    font-weight: bold;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
  
  .light a {
    color: var(--green-dark);
  }
  
  /* button:not('.box') { breaking for some reason in codepen? */
  button {
    display: inline-block;
    outline: none;
    padding: 8px 14px;
    background: var(--dark);
    border: solid 2px var(--light);
    color: var(--light);
    text-decoration: none;
    border-radius: 99px;
    padding: 12px 25px;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    line-height: 18px;
  }
  
  .gsap-logo {
    width: 20vw;
    max-width: 150px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    mix-blend-mode: difference;
    z-index: 9999;
  }
  
  .gsap-logo img {
    width: 100%;
  }
  
  .gsap-3-logo {
    width: 20vw;
    max-width: 150px;
    position: fixed;
    bottom: 15px;
    right: 15px;
  }
  
  /* panels  */
  
  .panel {
    min-height: 100vh;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  
  .panel.gray {
    background-color: #777;
    background-image: var(--gradient-macha);
  }
  .panel.blue {
    background-color: #2c7ad2;
    background-image: var(--gradient-summer-fair);
  }
  .panel.orange {
    background-color: #e77614;
    background-image: var(--gradient-orange-crush);
  }
  
  .panel.red {
    background-color: #c82736;
    background-image: var(--gradient-lipstick);
  }
  
  .panel.purple {
    background-color: #8d3dae;
    background-image: var(--gradient-purple-haze);
  }
  .panel.green {
    background-color: #28a92b;
    background-image: var(--gradient-macha);
  }
  
  /* SCROLL DOWN */
  .scroll-down {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    color: black;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16px;
    overflow: visible;
  }
  .scroll-down .arrow {
    position: relative;
    top: -4px;
    margin: 0 auto;
    width: 20px;
    height: 20px;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj4KPHBhdGggZmlsbD0iYmxhY2siIGQ9Ik00ODMuMiwxOTIuMmMtMjAuNS0yMC41LTUzLjUtMjAuOC03My43LTAuNkwyNTcsMzQ0LjFMMTA0LjUsMTkxLjZjLTIwLjItMjAuMi01My4yLTE5LjktNzMuNywwLjYKCWMtMjAuNSwyMC41LTIwLjgsNTMuNS0wLjYsNzMuN2wxOTAsMTkwYzEwLjEsMTAuMSwyMy40LDE1LjEsMzYuOCwxNWMxMy4zLDAuMSwyNi43LTQuOSwzNi44LTE1bDE5MC0xOTAKCUM1MDMuOSwyNDUuNyw1MDMuNywyMTIuNyw0ODMuMiwxOTIuMnoiLz4KPC9zdmc+);
    background-size: contain;
  }
  
  /* h1, h2, p, li {
    max-width: 800px;
  breaking stuff
  }
   */
  .panel p strong,
  .panel li strong {
    color: white;
    font-weight: 400;
  }
  .panel p code,
  .panel li code {
    background-color: rgba(255, 255, 255, 0.15);
    padding: 2px 4px;
    border-radius: 5px;
  }
  .panel li {
    margin: 0;
  }
  
  pre.prettyprint {
    max-width: 100%;
    overflow: auto;
  }
  
  ul pre.prettyprint {
    margin-left: -0.5rem;
  }
  
  /* prism */
  
  pre {
    --ifm-pre-background: var(--ifm-color-gray-800) !important;
    --ifm-font-family-monospace: 'Fraktion Mono';
  }
  /* Plain text style */
  code {
    border: none;
    border-radius: 4px;
    padding: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
    vertical-align: baseline;
    background: #28292b;
    color: #f5f4e6de;
  }
  
  /* Comment */
  code .token.comment {
    color: #9c9c9c;
    font-style: italic;
  }
  
  /* String and inserted */
  code .token.string,
  code .token.inserted {
    color: #63d55a;
  }
  
  /* Number */
  code .token.number {
    color: rgb(247, 140, 108);
  }
  
  /* Builtin, char, constant, and function */
  code .token.builtin,
  code .token.char,
  code .token.constant,
  code .token.function {
    color: rgb(130, 170, 255);
  }
  
  /* Punctuation and selector */
  code .token.punctuation,
  code .token.selector {
    color: rgb(199, 146, 234);
  }
  
  /* Variable */
  code .token.variable {
    color: rgb(191, 199, 213);
  }
  
  /* Class-name and attr-name */
  code .token.class-name,
  code .token.attr-name {
    color: rgb(255, 203, 107);
  }
  
  /* Tag and deleted */
  code .token.tag,
  code .token.deleted {
    color: rgb(255, 85, 114);
  }
  
  /* Operator */
  code .token.operator {
    color: rgb(137, 221, 255);
  }
  
  /* Boolean */
  code .token.boolean {
    color: rgb(255, 88, 116);
  }
  
  /* Keyword */
  code .token.keyword {
    font-style: italic;
  }
  
  /* Doctype */
  code .token.doctype {
    color: rgb(199, 146, 234);
    font-style: italic;
  }
  
  /* Namespace */
  code .token.namespace {
    color: rgb(178, 204, 214);
  }
  
  /* URL */
  code .token.url {
    color: rgb(221, 221, 221);
  }
  
  /* Hide elements visually, but not for screenreaders by clipping it */
  
  .vh {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }
  
  /* checkboxes */
  
  /*  Add a :before to each label and style this as the checkbox you want to have */
  .checkbox label {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  
  .checkbox label:before {
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: var(--light);
    display: inline-block;
    margin-right: 10px;
  }
  
  .checkbox input[type='checkbox']:checked ~ label:before {
    background: var(--green) no-repeat center;
    background-size: 12px 12px;
    background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  }
  
  .flair {
    width: 100px;
    height: 100px;
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-0.png');
    background-size: contain;
    background-repeat: no-repeat;
    will-change: transform;
  }
  
  .flair--2 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-2.png');
  }
  
  .flair--3 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-3.png');
  }
  
  .flair--4 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-4.png');
  }
  
  .flair--5 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-5.png');
  }
  
  .flair--6 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-6.png');
  }
  
  .flair--7 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-7.png');
  }
  
  .flair--8 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-8.png');
  }
  
  .flair--9 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-9.png');
  }
  
  .flair--10 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-10.png');
  }
  
  .flair--11 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-11.png');
  }
  
  .flair--12 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-12.png');
  }
  
  .flair--13 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-13.png');
  }
  
  .flair--14 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-14.png');
  }
  
  .flair--15 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-15.png');
  }
  
  .flair--16 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-16.png');
  }
  
  .flair--17 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-17.png');
  }
  
  .flair--18 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-18.png');
  }
  
  .flair--19 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-19.png');
  }
  
  .flair--20 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-20.png');
  }
  
  .flair--21 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-21.png');
  }
  
  .flair--22 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-22.png');
  }
  
  .flair--23 {
    background-image: url('https://gsap.com/_img/codepen/flair/Flair-23.png');
  }
  
  .flair-cont {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .gradient-green {
    background: var(--gradient-macha),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-green-2 {
    background: var(--gradient-emerald-city),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-orange {
    background: var(--gradient-orange-crush),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-purple {
    background: var(--gradient-purple-haze),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-blue-2 {
    background: var(--gradient-summer-fair),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-blue {
    background: var(--color-ui-gradient),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .gradient-red {
    background: var(--gradient-lipstick),
      url('https://gsap.com/_img/codepen/flair/noise.png'); /* Replace with the path to your noise texture image */
    background-blend-mode: color-dodge; /* Blend the noise texture with the gradient */
  }
  
  .noise {
    position: relative;
  }
  .noise:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: url('https://gsap.com/_img/codepen/flair/noise.png');
    opacity: 0.4;
    pointer-events: none;
  }
  