.funnel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    max-width: 500px; /* Adjust the width as needed */
    margin: 0 auto;
  }
  
  .funnel-stage {
    width: 20rem;
    height: 60px; /* Adjust the height as needed */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 5px 0;
    opacity: 0;;
  }
  
  .funnel-stage h2 {
    position: relative;
    z-index: 1;
    margin: 0;
  }
  
  .funnel-container .awareness {
    background-color: #003366;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  }
  
  .funnel-container .consideration {
    background-color: #FF0066;
    clip-path: polygon(10% 0%, 90% 0%, 80% 100%, 20% 100%);
  }
  
  .funnel-container .conversion {
    background-color: #00CCFF;
    clip-path: polygon(20% 0%, 80% 0%, 70% 100%, 30% 100%);
  }
  
  .funnel-container .loyalty {
    background-color: #9933FF;
    clip-path: polygon(30% 0%, 70% 0%, 60% 100%, 40% 100%);
  }

.funnel-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: Arial, sans-serif;
    text-align: center;
    align-items: center;
  }
  
  .table-header, .table-row {
    display: flex;
    justify-content: space-around;
    opacity: 0;
  }
  
  .table-cell {
    padding: 10px;
    border: 1px solid #ddd;
    flex: 1;
  }
  
  .table-header .table-cell {
    
    font-weight: bold;
    color:black;
    border:none;
  }
  
  .awareness .table-cell {
    background-color: #003366;
    color: white;
  }
  
  .consideration .table-cell {
    background-color: #FF0066;
    color: white;
  }
  
  .conversion .table-cell {
    background-color: #00CCFF;
    color: white;
  }
  
  .loyalty .table-cell {
    background-color: #9933FF;
    color: white;
  }

  svg {
    width: 600px;
    max-height: 90vh;
  }
  